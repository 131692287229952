import {InferTranslation} from 'typed-locale';

export const en = {
  locale: 'en',
  login: 'Login',
  logout: 'Logout',
  signup: 'Signup',
  blog: 'Blog',
  ok: 'Ok',
  try: 'Try',
  start: 'Start',
  ask_for_demo: 'Ask for demo',
  validate: 'Validate',
  email: 'Email',
  articles: 'Articles',
  email_example: 'john.do@gmail.com',
  password: 'Password',
  comming_soon: 'Comming soon',
  forgot_password: 'Forgot password',
  location_is_required: 'Location is required',
  we_need_your_location: 'We need your location for setting the initial position of the map.',
  head_title: 'Questovery - Create your own digital scavenger hunt',
  teams_count_during_the_month: 'Teams count during the month',
  welcome: 'Welcome',
  welcome_message:
    'Welcome to Questovery, you will receive an email to validate your account and define your password. Please check your inbox.',
  head_description:
    'Create your own digital scavenger hunt for mobile. Make personalized team building, treasure hunt, escape game, city tour, birthday, and more.',
  repeat_password: 'Repeat password',
  send_reset_password_email: 'Send reset password email',
  email_sent: 'Email sent',
  reset_email_sent: 'A reset email has been sent to your email address, please check your inbox.',
  back_to_login: 'Back to login',
  scan_qr_code: 'Scan QR code',
  not_yet_registered: 'Not yet registered?',
  already_have_an_account: 'Already have an account?',
  company: 'Company',
  features: 'Features',
  prices_in_euros_vat_excluded: 'Prices in euros, VAT excluded',
  read_terms_of_use: 'Read terms of use',
  read_end_user_license_agreement: 'Read end user license agreement',
  read_privacy_policy: 'Read privacy policy',
  accept_terms_of_use: 'Accept terms of use',
  site: 'Site',
  home: 'Home',
  docs: 'Docs',
  about: 'About',
  contact_us: 'Contact us',
  legal: 'Legal',
  terms_of_use: 'Terms of use',
  end_user_license_agreement: 'End user license agreement',
  privacy_policy: 'Privacy policy',
  cta_title: 'Make your own scavenger hunt with the best user experience',
  cta_subtitle:
    'Questovery is a platform that allows you to create your own scavenger hunt in a few clicks. Make team building, treasure hunt, escape game, city tour, birthday, and more.',
  cta_feat_1_title: 'Combine the best of the real and the virtual',
  cta_feat_1_subtitle: 'Our platform allows you to create quests with a geolocation system, and QR codes.',
  cta_feat_2_title: 'A quest that adapts to your needs',
  cta_feat_2_subtitle: 'Personalize your quest with your own questions, your own images, your own videos, and more.',
  cta_feat_3_title: 'User experience at the heart of our concerns',
  cta_feat_3_subtitle:
    'Our platform is designed to be as intuitive as possible. Our mobile app is constantly evolving to offer the best user experience.',
  cta_screen_1_title: 'Intuitive interface',
  cta_screen_1_subtitle:
    'With our web app, you can create your quest in few minutes. Interactive map, drag and drop, everything is designed to make your life easier.',
  cta_price_title: 'Simple pricing',
  cta_price_subtitle:
    'No hidden fees, no credit card required. No engagement. Start for free and upgrade when you need it.',
  plan_free: 'Free',
  plan_free_description: 'For casual users that want to try the platform',
  plan_questovery: 'Questovery plan',
  plan_questovery_description:
    'Pay as you go, you will be charged only for the teams that play your quests during the month.',
  plan_pro: 'Pro',
  plan_pro_description:
    'For professionals like event agencies, team building companies that want to create quests for their clients',
  plan_max: 'Max',
  plan_max_description: 'For touristic places that want to create quests for their visitors',
  plan_unlimited: 'Unlimited',
  plan_unlimited_description: "If you don't like limits",
  full_edition_software: 'Full edition software',
  manage_steps_and_questions: 'Manage steps and questions',
  geolocate_steps: 'Geolocate steps',
  add_images_and_audio: 'Add images and audio',
  support_with_chat_and_email: 'Support with chat and email',
  all_features_of_free_plan: 'All features of free plan',
  unlimited_quests: 'Unlimited quests',
  unlimited_teams: 'Unlimited teams',
  invite_teams_up_to_25_by_quest: 'Invite teams up to 25 by quest',
  a_quest_with_unlimited_teams: 'A quest with unlimited teams',
  mobile_access: 'Mobile access',
  lunch_mobile_app_and_scan_qr_code_to_manage_quest: 'Lunch mobile app and scan QR code to manage quest',
  my_quests: 'My quests',
  update: 'Update',
  new_quest: 'New quest',
  new_quest_description: 'Create a new quest. You will be able to edit it and test it before sharing it.',
  name: 'Name',
  create: 'Create',
  settings: 'Settings',
  profile: 'Profile',
  profile_description: 'Manage your profile and invoicing information.',
  country: 'Country',
  save: 'Save',
  month: 'Month',
  months: 'Months',
  subscriptions: 'Subscriptions',
  you_have_no_subscription:
    'You can test the platform for free by creating quests but you will not be able to invite teams. When you are ready, you can subscribe to start inviting teams.',
  subcribe: 'Subcribe',
  manage_subscriptions: 'Manage subscriptions',
  manage_invoices: 'Manage invoices',
  delete_account: 'Delete account',
  delete_account_description: 'Delete your account',
  delete: 'Delete',
  cancel: 'Cancel',
  confirm: 'Confirm',
  delete_account_confirm: 'Are you sure you want to delete your account?',
  your_subscription: 'Your subscription',
  verify_your_email: 'Verify your email',
  send_verification_email: 'Send verification email',
  verify_your_email_description: 'Verify your email to access all the features of questovery.',
  verify: 'Verify',
  activate_your_subscription: 'Activate your subscription',
  activate_your_subscription_description:
    'Activate your subscription to get access to all the features of questovery including, team invitation, and more.',
  your_subscription_description: 'Get invoices, manage your subscription with stripe',
  back_to_quest: 'Back to quest',
  delete_step: 'Delete step',
  delete_step_confirm: 'Are you sure you want to delete this step?',
  define_password: 'Define password',
  audio: 'Audio',
  description: 'Description',
  audio_comming_soon: 'Audio is comming soon, you will be able to add audio to your steps.',
  enigma_type: 'Enigma type',
  no_enigma: 'No enigma',
  message_without_enigma: 'Message without enigma',
  code: 'Code',
  mcq: 'MCQ',
  bonus: 'Bonus',
  malus: 'Malus',
  options: 'Options',
  add_option: 'Add option',
  delete_quest: 'Delete quest',
  delete_quest_confirm: 'Are you sure you want to delete this quest?',
  steps: 'Steps',
  steps_description:
    'Create steps for your quest, each step is a place where teams will have to go. Sort them by drag and drop.',
  startings: 'Startings',
  arrival: 'Arrival',
  here_is_the_results_of_your_quest: 'Here is the results of your quest.',
  invite_teams_with_qr_code: 'Invite teams with QR code',
  invite_team_qr_code_title: 'This is the QR code to invite teams',
  invite_team_qr_code_description:
    'Teams will have to scan this QR code to join the quest. When your quest is ready, you can share this QR code with your teams.',
  launch_the_team_quest: 'Launch the team quest',
  launch_the_team_quest_description:
    'When you launch the team quest, first step will be unlocked and timer will start.',
  delete_team: 'Delete team',
  delete_team_confirm: 'Are you sure you want to delete this team?',
  forgot_email_content:
    'You are receiving this email because you have requested a password reset for your user account at Questovery. Please see the code below to reset your password or click on the link below.',
  welcome_email_content:
    'You are receiving this email because you have created an account at Questovery.  Please see the code below to define your password or click on the link below.',
  welcome_unlimited_email_content:
    'Thank you to be part of Questovery. Your account is now activated. Your password is:',
  signup_email_title: 'Welcome to Questovery',
  signup_email_content: 'You are receiving this email because you have created an account at Questovery.',
  question_message: 'Question / message',
  look_in_the_map: 'Look in the map',
  test_the_quest: 'Test the quest',
  test_the_quest_description: 'Start a fake team and test the quest to see if everything is ok.',
  test: 'Test',
  updates_saved: 'Updates saved',
  image_deleted: 'Image deleted',
  select: 'Select',
  image: 'Image',
  record: 'Record',
  play: 'Play',
  cannot_retrieve_user: 'Canot retrieve user',
  permission_denied: 'Permission denied',
  location: 'Location',
  enigma: 'Enigma',
  error: 'Error',
  new_step: 'New step',
  new_step_description: "Add a new step to your quest, it's a place where teams will have to go during the quest.",
  answer: 'Answer',
  add: 'Add',
  malus_by_wrong_answer: 'Malus by wrong answer',
  allow_teams_to_start_by_themselves: 'Allow teams to start by themselves',
  activate_geolocation_for_this_step: 'Activate geolocation for this step',
  wrong_answer: 'Wrong answer',
  wrong_answer_description: 'Wrong answer, please try again.',
  good_answer: 'Good answer',
  good_answer_description: 'Good answer, you can continue.',
  lock: 'Lock',
  unlock: 'Unlock',
  your_team: 'Your team',
  player_name: 'Player name',
  join: 'Join',
  contact_us_description:
    'If yu have any question, suggestion, or if you want to report a bug, please contact us by email or by chat on our website.',
  team_members: 'Team members',
  team_members_description:
    'Theses are the members of your team. You can invite new members by sharing the QR code of your team.',
  manage: 'Manage',
  join_quest: 'Join quest',
  join_quest_description: 'Join the quest by scanning the QR code of the quest.',
  quest_in_progress: 'Quest in progress',
  quest_in_progress_description: 'The quest is in progress, quickly join the quest to not miss the beginning.',
  waiting_for_the_quest_to_start: 'Waiting for the quest to start',
  scan_the_qr_code_to_login: 'Scan the QR code to login',
  go_back: 'Go back',
  you_unlocked_the_next_step: 'You unlocked the next step',
  send_answer: 'Send answer',
  next_step: 'Next step',
  come_closer: 'Come closer',
  come_closer_description: 'You are too far from the step, come closer to unlock the next step.',
  quest_ended: 'Quest ended',
  quest_ended_description: 'The quest is ended, thank you for playing.',
  see_results: 'See results',
  admin: 'Admin',
  admin_actions_description: 'You can unlock steps to avoid geolocation et simulate good or wrong answers.',
  select_the_good_aswer: 'Select the good aswer',
  upgrade_your_plan: 'Upgrade your plan',
  upgrade_your_plan_description: 'You need to upgrade your plan to unlock this feature.',
  signup_subscription: 'You need to subscribe before using the platform.',
  signup_subscription_description:
    'You will be redirected to the subscription page for activating your account. You have 30 days to test the platform for free. After that you will be charged only for the teams that play your quests during the month. Each month we offer you 5 teams for free.',
  onboarding: {
    welcome: {
      welcome_to_questovery: 'Welcome to Questovery',
      welcome_to_questovery_description:
        'With Questovery you can create your own digital scavenger hunt for mobile. Make personalized team building, treasure hunt, escape game, city tour, birthday, and more.',
      let_s_get_started: "Let's get started",
    },
    tracking_consent: {
      consent: 'Consent',
      consent_description:
        'Before starting, we need your consent to use some of your data. Please read the terms of use, the end user license agreement, and the privacy policy. We only use your data for the purpose of the platform.',
      read_terms_of_use: 'Read terms of use',
      read_end_user_license_agreement: 'Read end user license agreement',
      read_privacy_policy: 'Read privacy policy',
      accept: 'I accept',
    },
    location_consent: {
      location: 'Location',
      location_consent_description:
        "Questovery require your location to be able to geolocate the steps of the quest. We don't store your location, we only use it for unlocking the steps when you are close to them.",
      accept: 'I accept',
    },
    choices: {
      how_do_you_want_to_use_questovery: 'How do you want to use Questovery?',
      choices_description: 'Choose the use of Questovery that best suits your needs.',
      individual: 'For me and my friends',
      game_choice: 'Just play',
      event: 'For an event',
      tourism: 'For a touristic place',
      create_a_scavenger_hunt: 'Create a scavenger hunt',
    },
    individual: {
      you_want_to_create_a_quest: 'You want to create a quest',
      you_want_to_create_a_quest_description:
        'With Questovery you can prepare a quest for your friends or family. Add steps, questions, and photos to make the quest more fun. When you are ready, you can invite your friends to play the quest with the mobile app and a QR code.',
      for_quest_creation_use_our_web_app:
        'For quest creation, please go to our web app and register to start creating your quest.',
      register_on_questovery: 'Register on questovery.com',
      i_already_have_an_account: 'I already have an account',
    },
    game: {
      you_want_to_play_a_quest: 'You want to play a quest',
      you_want_to_play_a_quest_description:
        'With Questovery you can play a quest that someone has created. You just have to scan the QR code of the quest to start playing. You will have to go to different places to unlock the next steps and answer the questions.',
      start_playing: 'Start playing',
    },
    organize: {
      you_re_already_a_quest_creator: "You're already a quest creator",
      get_qr_code_to_login_description:
        "You're already have an account from the web app, get the QR code to login on mobile.",
      manage_my_quests: 'Manage my quests',
    },
    event: {
      you_want_to_create_a_quest_for_an_event: 'You want to create a quest for an event',
      you_want_to_create_a_quest_for_an_event_description:
        'With Questovery you can create a quest for an event like a wedding, a team building, a school trip, or a birthday. Add steps, questions, and photos to make the quest more fun. When you are ready, you can invite your participants to play the quest with the mobile app and a QR code.',
      for_quest_creation_use_our_web_app:
        'For quest creation, please go to our web app and register to start creating your quest.',
      register_on_questovery: 'Register on questovery.com',
      i_already_have_an_account: 'I already have an account',
    },
    tourism: {
      you_want_to_create_a_quest_for_a_touristic_place: 'You want to create a quest for a touristic place',
      you_want_to_create_a_quest_for_a_touristic_place_description:
        'With Questovery you can create a quest for a touristic place, a city tour, or a museum. Add steps, questions, and photos to make the quest more fun. When you are ready, share publicly the QR code of the quest to allow visitors to play the quest with the mobile app.',
      for_quest_creation_use_our_web_app:
        'For quest creation, please go to our web app and register to start creating your quest.',
      register_on_questovery: 'Register on questovery.com',
      i_already_have_an_account: 'I already have an account',
    },
  },
} as const;

export type Dictionary = InferTranslation<typeof en>;
